<template>
  <div class="portal-page">

    <h2 class='allpha-title'>mes projets</h2>

    <div class="project-list-viewer">
      <FontAwesomeIcon icon='chevron-left' @click='navProject(-1)' :class='{ inactive: projectIndex===0 }' class='left pro-nav-icon' ></FontAwesomeIcon>
      <!-- <ul class='project-list'>
        <li v-for='(pro, index) in list' :key='index'> -->
          <div v-if='list.length > 0' class="project-cont">
            <h3 class='allpha-title'>{{ project.name }}</h3>
            <p class='pro-description'>{{ project.description }}</p>
            <button class='go-to-btn' @click='projectVis=true'>allez au projet</button>
            <div v-if='project.invoiceId' class="payment-cont">
              <h5 :class='{ urgent: project.paymentStatus==="pending"}'>Payment status: {{ project.paymentStatus }}</h5>
              <button class='payment-btn' @click='invoiceViewer = true'>Facturation</button>
            </div>
          </div>

          <h5 v-else>Loading info...</h5>
        <!-- </li>
      </ul> -->
      <FontAwesomeIcon icon='chevron-right' @click='navProject(1)' :class='{ inactive: list.length-1===projectIndex }' class='right pro-nav-icon' ></FontAwesomeIcon>
    </div>


    <transition appear name="very-short" mode="out-in">
      <InvoiceViewer @closeViewer='invoiceViewer=false' v-if='invoiceViewer===true' :project='list[this.projectIndex]'></InvoiceViewer>
      <SelectedProject @closeProject='projectVis=false' @goToInvoice='invoiceViewer=true' v-if='projectVis===true' :project='list[this.projectIndex]'></SelectedProject>
    </transition>

  </div>
</template>

<script>
import InvoiceViewer from '@/components/portal/InvoiceViewer.vue';
import SelectedProject from '@/components/portal/SelectedProject.vue';

import { getProjects } from '@/apiCall';

import { mapGetters } from 'vuex';

export default {
  name: 'PortalProject',
  data(){
    return {
      invoiceViewer: false,
      //
      projectVis: false,
      //
      projectIndex: 0,
      //
      loaded: false,
      loading: false,
      list: []
    };
  },
  props: {
    currentUser: Object
  },
  components: {
    InvoiceViewer,
    SelectedProject
  },
  computed: {
    project(){
      return this.list[this.projectIndex]
    },
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated'
    })
  },
  methods: {
    navProject(incDec){
      if(this.projectIndex===0&&incDec===-1 || this.projectIndex+1===this.list.length){
        return ;
      } else {
        this.projectIndex =  this.projectIndex + incDec;
      }
    },
    async fetchProjects(){
      try {

        this.loading = true;

        this.list = await getProjects(this.currentUser.user.id)

        this.loaded = true;
        this.loading = false;

      } catch (e) {
        console.log(e)
      }
    },
  },
  watch: {
    isAuthenticated(val){
      if(val===true){
        this.fetchProjects()
      }
    }
  },
  mounted(){
    this.fetchProjects()
  }
}
</script>
