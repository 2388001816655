<template>
<div class="project-modal">
  <FontAwesomeIcon class='close-icon' icon='times' @click='closeProject'></FontAwesomeIcon>
  <h2 class='allpha-title'>{{ project.name }}</h2>

  <div class="info-cont">
    <div class="detail">

      <div v-if='project.teamNames' class="detail-line">
        <FontAwesomeIcon icon='camera'></FontAwesomeIcon>
        <h5>{{ project.teamNames }}</h5>
      </div>

      <div v-if='project.date' class="detail-line">
        <FontAwesomeIcon icon='calendar'></FontAwesomeIcon>
        <h5>{{ parseDate(project.date) }}</h5>
      </div>

      <div v-if='project.location' class="detail-line">
        <FontAwesomeIcon icon='map-marker-alt'></FontAwesomeIcon>
        <h5>{{ project.location }}</h5>
      </div>

      <div class="payment-cont">
        <h5 :class='{ urgent: project.paymentStatus==="pending"}'>État de facturation: {{ project.paymentStatus }}</h5>
        <button @click='goToInvoice' class='payment-btn'>Facturation</button>
      </div>
    </div>

    <p>{{ project.description }}</p>

    <button @click='selectAllProject'>Télécharger tout le projet</button>

  </div>


  <!-- NOTE VIDEO LIST -->

  <div class="media-cont">
    <h3 class='allpha-title'>video</h3>
    <ul class='media-list'>
      <li @click='toggleDownloadList("video",index)' v-for='(video, index) in video' :key='index'>
        <!-- <FontAwesomeIcon class='download-icon' icon='cloud-download-alt' @click='downloadItem(video)'></FontAwesomeIcon> -->
        <FontAwesomeIcon class='check-icon' icon='check' :class='{ selected: downloadList.video.indexOf(index)!==-1}'></FontAwesomeIcon>

        <video width="320" height="240" controls controlsList="nodownload">
          <source :src="video.url.original" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </li>
    </ul>
  </div>



  <div class="media-cont">
    <h3 class='allpha-title'>images</h3>
    <div class="image-style-cont">
      <FontAwesomeIcon :class='{ selected: imageStyle==="big" }' icon='image' @click='imageStyle="big"'></FontAwesomeIcon>
      <FontAwesomeIcon :class='{ selected: imageStyle==="min" }' icon='images' @click='imageStyle="min"'></FontAwesomeIcon>
    </div>
    <ul class='media-list' :class='{ minimal: imageStyle==="min" }'>
      <li v-for='(image, index) in image' :key='index'>
        <!-- <FontAwesomeIcon class='download-icon' icon='cloud-download-alt' @click='downloadItem(image)'></FontAwesomeIcon> -->
        <FontAwesomeIcon class='expand-icon' icon='expand-alt' @click='imageViewerIndex=index'></FontAwesomeIcon>
        <FontAwesomeIcon @click='toggleDownloadList("image",index)' class='check-icon' icon='check' :class='{ selected: downloadList.image.indexOf(index)!==-1}'></FontAwesomeIcon>

        <img :src='image.url.medium' />
      </li>
    </ul>
  </div>



  <!-- NOTE IMAGE VISUALIZER -->

  <div v-if='imageViewerIndex!==null' class="image-viewer">
    <FontAwesomeIcon class='close-icon' icon='times' @click='imageViewerIndex=null'></FontAwesomeIcon>
    <FontAwesomeIcon class='nav-icon left' icon='chevron-left' @click='indOrDec(-1)'></FontAwesomeIcon>
    <transition name="modal-transition" mode="out-in">
      <img class='target-image' :key='imageViewerIndex' :src='image[imageViewerIndex].url.medium' />
    </transition>
    <FontAwesomeIcon class='nav-icon right' icon='chevron-right' @click='indOrDec(1)'></FontAwesomeIcon>
  </div>



  <!-- NOTE DOWNLOAD DRAWER -->

  <div :class='{ show: downloadQte > 0 }' class="download-bar">
    <button @click='initDownload'>Télécharger {{ downloadQte }} items sélectionné(s)</button>
  </div>


  <!-- NOTE DOWNLOAD ANIMATION -->
  <transition appear name="modal-transition" mode="out-in">
    <div v-if='downloadAnimation===true' class="download-modal">
      <FontAwesomeIcon class='download-icon' icon='cloud-download-alt'></FontAwesomeIcon>
      <h2>Éléments à loader {{ downloadQte }}</h2>
    </div>
  </transition>




</div>
</template>

<script>
import Axios from 'axios';

import * as dayjs from 'dayjs';

import {
  getProjectsMedia
} from '@/apiCall';

export default {
  name: 'SeletedProject',
  data() {
    return {
      downloadAnimation: false,
      //
      imageViewerIndex: null,
      //
      imageStyle: 'big',
      //
      loaded: false,
      loading: false,
      image: [],
      video: [],
      audio: [],
      downloadList: {
        image: [],
        video: [],
        audio: [],
      }
    };
  },
  computed: {
    downloadQte() {
      let qte = this.downloadList.image.length + this.downloadList.video.length + this.downloadList.audio.length;
      return qte;
    }
  },
  props: {
    project: Object
  },
  methods: {
    goToInvoice(){
      this.$emit('goToInvoice')
    },
    selectAllProject() {
      // let list = ['image', 'video', 'audio']
      // for (var i = 0; i < this.list.length; i++) {
      //   let media = list[i];
      //   console.log(media)
      //   for (var j = 0; j < this[media].length; j++) {
      //     this.downloadList[media].push(j)
      //   }
      // }
      for (var j = 0; j < this.image.length; j++) {
        this.downloadList.image.push(j)
      }
      for (var k = 0; k < this.video.length; k++) {
        this.downloadList.video.push(k)
      }
      for (var l = 0; l < this.audio.length; l++) {
        this.downloadList.audio.push(l)
      }
    },
    initDownload() {

      this.downloadAnimation = true; // // NOTE: ANIMATION CLOSED IN WATHCER

      for (var media in this.downloadList) {
        if ({}.hasOwnProperty.call(this.downloadList, media)) {
          for (var i = 0; i < this.downloadList[media].length; i++) {
            let originIndex = this.downloadList[media][i]
            let med = this[media][originIndex];
            console.log(med)
            // this.downloadItem(med, med._id, media, originIndex)
            if(med.mediaType==='image'){
              this.downloadItem(med.url.original, med._id, media, originIndex)

            } else {
              this.downloadItem(med.url, med._id, media, originIndex)
            }
          }
        }
      }
    },
    indOrDec(incDec) {
      if ((incDec === -1 && this.imageViewerIndex === 0) || (incDec === 1 && this.imageViewerIndex === this.image.length - 1)) {
        return;
      } else {
        this.imageViewerIndex = this.imageViewerIndex + incDec;
      }
    },
    toggleDownloadList(type, originIndex) {
      let ind = this.downloadList[type].indexOf(originIndex);
      if (ind === -1) {
        this.downloadList[type].push(originIndex)
      } else {
        this.downloadList[type].splice(ind, 1)
      }
    },
    closeProject() {
      this.$emit('closeProject')
    },
    parseDate(date) {
      return dayjs(date).format('LL');
    },
    downloadItem(
      url,
      _id,
      mediaType,
      originIndex
    ) {

      console.log(url)
      let label = url.split(_id)[1];

      Axios.get(url, {
          responseType: 'blob'
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: `${mediaType}/*`
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
          this.toggleDownloadList(mediaType, originIndex)
        }).catch(console.error)
    },
    async fetchMedia() {
      try {

        let list = await getProjectsMedia(this.project._id)

        let image = [];
        let video = [];
        let audio = [];
        for (var i = 0; i < list.length; i++) {
          let media = list[i];
          switch (media.mediaType) {
            case 'image':
              image.push(media);
              break;
            case 'video':
              video.push(media);
              break;
            case 'audio':
              audio.push(media);
              break;
          }
        }

        this.image = image;
        this.video = video;
        this.audio = audio;

      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    downloadQte(val) {
      if (val === 0) {
        this.downloadAnimation = false; // // NOTE: ANIMATION INITIATED IN ON DOWNLOAD
      }
    }
  },
  mounted() {
    this.fetchMedia()
  }
}
</script>
