<template>
  <div class="team">
    <div class="header">
      <div class="a-team">
        <h2>A</h2>
        <h3>team</h3>
      </div>
      <h4>Petite équipe, grandes ambitions...</h4>
    </div>

    <div class="main">
      <div
        class="teammate sam"
        @click="selectTeammate('sam')"
        :class="{ selected: teammate === 'sam' }"
      >
        <img
          src="https://allfredo.s3.ca-central-1.amazonaws.com/allpha/P1022428.jpg"
        />
        <h3>Sam</h3>
      </div>

      <div
        class="teammate jm"
        @click="selectTeammate('jm')"
        :class="{ selected: teammate === 'jm' }"
      >
        <img
          src="https://allfredo.s3.ca-central-1.amazonaws.com/allpha/P1022454.jpg"
        />
        <h3>JM</h3>
      </div>
    </div>

    <transition name='preview-transition' mode='out-in'>
    <div v-if="teammate !== null" class="teammate-display">

      <div class='mobile-folio'>
        <div
          class="mobile-sam mobile-teammate"
          :class="{ selected: teammate === 'sam' }"
          @click="selectTeammate('sam')"
        >
          <img
            src="https://allfredo.s3.ca-central-1.amazonaws.com/allpha/P1022428.jpg"
          />
          <h2>Sam</h2>
        </div>
        <div
          class="mobile-jm mobile-teammate"
          :class="{ selected: teammate === 'jm' }"
          @click="selectTeammate('jm')"
        >
          <img
            src="https://allfredo.s3.ca-central-1.amazonaws.com/allpha/P1022454.jpg"
          />
          <h2>JM</h2>
        </div>
      </div>

      <div
        class="folio sam-folio"
        :class="{ selected: teammate === 'sam' }"
        @click="selectTeammate('sam')"
      >
        <img
          src="https://allfredo.s3.ca-central-1.amazonaws.com/allpha/P1022428.jpg"
        />
        <h2>Sam</h2>
      </div>

        <div class="display" :class='teammate==="sam" ? "back-dark": "back-light"'>
          <MediaList :setModal='setModal' :teammate='teammate' />
        </div>


      <div
        class="folio jm-folio"
        :class="{ selected: teammate === 'jm' }"
        @click="selectTeammate('jm')"
      >
        <img
          src="https://allfredo.s3.ca-central-1.amazonaws.com/allpha/P1022454.jpg"
        />
        <h2>JM</h2>
      </div>
    </div>
  </transition>

    <!-- <transition name='modal-transition' mode='out-in'>
      <Modal :teammate='teammate' :index='index' :navPortfolio='navPortfolio' :closeModal='closeModal' />
    </transition> -->

  </div>
</template>

<script>
import MediaList from '../components/MediaList.vue';
// import Modal from '../components/Modal.vue';

export default {
  props: {
    setModal: Function
  },
  components: {
    MediaList
    // Modal
  },
  data() {
    return {
      index: null,
    };
  },
  computed: {
    teammate(){
      return this.$route.params.teammate;
    }
  },
  methods: {
    setPreview(index){
      this.index = index;
    },
    selectTeammate(teammate) {
      this.$router.push(`/team/${teammate}`)
    },
    navPortfolio(ind){
      this.index = ind;
    },
    closeModal(){
      this.index = null;
    }
  }
};
</script>
