<template>
  <ul class="album sam">
    <li v-for="(l, index) in list" :key="index" @click="fullscreen({list, index})">
      <img :src="l.url" />
    </li>
  </ul>
</template>

<script>
import portfolio from "../mediaSrc/portfolio.js";

export default {
  props: {
    teammate: String,
    setModal: Function
  },
  computed: {
    list() {
      return portfolio[this.teammate];
    }
  },
  methods: {
    fullscreen(data) {
      this.setModal(data);
    }
  }
};
</script>
