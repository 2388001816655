<template>
  <div class="portal-page">
    <h3>Changez votre mot de passe</h3>
    <form @submit.prevent class='vert-cont'>
      <input type="password" v-model='password' placeholder="Nouveau mot de passe"/>
      <input type="password" v-model='confirm' placeholder="Confirmation mot de passe"/>
      <button :disabled='isSame===false' @click='setPassword'>Set password</button>
    </form>
  </div>
</template>

<script>
import { setNewPassword } from '@/apiCall';

export default {
  name: 'PasswordReset',
  data(){
    return {
      password: '',
      confirm: ''
    };
  },
  props: {
    setAlert: Function
  },
  methods: {
    async setPassword(){
      try {

        if(this.password!==this.confirm){
          this.setAlert({
            type: 'info',
            data: {
              status: 'error',
              message: 'Opération non disponible, veuiller communiquer avec nous au 418-802-4227.'
            }
          })
          return;
        }

        let { userId, token } = this.$route.params

        let data = {
          password: this.password,
          secret: token,
          userId
        }

        await setNewPassword(data)

        this.$router.push('/')

      } catch (e) {
        this.setAlert({
          type: 'info',
          data: {
            status: 'error',
            message: 'Opération non disponible, veuiller communiquer avec nous au 418-802-4227.'
          }
        })
        console.log(e)
      }
    }
  },
  computed: {
    isSame(){
      return this.password===this.confirm;
    }
  }
}
</script>
