<template>

  <div v-if='resetCreator===false' class="portal-page sign-in">
    <h2>Connection client</h2>
    <form @submit.prevent>
      <input type="email" v-model='email' placeholder="info@tonemail.com" />
      <input type="password" v-model='password' placeholder="Password"/>
      <h5 class='reset-link' @click='resetCreator=true'>Mot de pass oublié</h5>
      <button @click='submit'>Connection</button>
    </form>
  </div>

  <div v-else class="portal-page sign-in">
    <h2>Réinitialiser le mot de passe</h2>
    <form @submit.prevent>
      <input type="email" v-model='email' placeholder="info@tonemail.com" />
      <button @click='resetPassword'>Réinitialiser</button>
    </form>
  </div>

</template>

<script>
import { signUserIn, resetCustomerPassword } from '@/apiCall';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SignIn',
  data(){
    return {
      resetCreator: false,
      email: '',
      password: '',
    };
  },
  props: {
    setAlert: Function
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated'
    })
  },
  methods: {
    async resetPassword(){
      try {

        await resetCustomerPassword({email:this.email})

        this.setAlert({
          type: 'info',
          data: {
            status: 'success',
            message: 'Vérifier votre address courriel!'
          }
        })

        this.$router.push('/')

      } catch (e) {

                this.setAlert({
                  type: 'info',
                  data: {
                    status: 'error',
                    message: 'Réinitialisation de mot passe échoué, contactez Allpha.'
                  }
                })
        console.log(e)
      }
    },
    async submit(){
      try {

        let data = {
          email: this.email,
          password: this.password
        }

        let { userInfo, token } = await signUserIn(data);

        localStorage.setItem('allpha_token', token)

        this.setCurrentUser(userInfo)

        this.$router.push(`/portal/project`)

      } catch (e) {
        console.log(e)
      }
    },
    ...mapActions({
      setCurrentUser: 'user/setCurrentUser'
    })
  },
  mounted(){
    if(this.isAuthenticated===true){
      this.$router.push('/portal/project')
    }
  }
}
</script>
