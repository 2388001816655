<template>
  <div class="invoice-modal">

    <FontAwesomeIcon icon='times' class='close-icon' @click='$emit("closeViewer")'></FontAwesomeIcon>

    <div class="invoice">

      <div class="header">
        <h1 class='alpha-title'>Allpha</h1>
        <div class="hori-cont-list">
          <h4>Par</h4>
          <h5>AXYO Divertissement Inc.</h5>
        </div>
        <h5 class='alpha-title small'>(418)802-4227 WWW.ALLPHA.CA SAM.POULIN@GOAXYO.COM</h5>
      </div>

      <div class="header-info">
        <div class="line">
          <h4 class='info'>Facturé à</h4>
          <h4>{{ project.customerId.customerName }}</h4>
        </div>
        <div class="line">
          <h4 class='info'>Status</h4>
          <h4>{{ project.invoiceId.paymentStatus }}</h4>
        </div>
        <div class="line">
          <h4 class='info'>Addresse</h4>
          <h4>{{ project.customerId.address }}</h4>
        </div>
        <div class="line">
          <h4 class='info'>Projet</h4>
          <h4>{{ project.name }}</h4>
        </div>
        <div class="line">
          <h4 class='info'>Date</h4>
          <h4>{{ parseDate(project.invoiceId.createdAt) }}</h4>
        </div>
      </div>

      <div class="invoice-main">
        <div class="invoice-main-header">
          <h4 class='desc-cont'>service</h4>
          <h4>qte</h4>
          <h4>taux</h4>
          <h4>prix</h4>
        </div>

        <div class="empty-line"/>

        <div class="invoice-line" v-for='(line, index) in project.invoiceId.items' :key='index'>
          <p class='desc-cont'>
            {{ line.description }}
          </p>
          <h4>{{ line.qte }}</h4>
          <h4>{{ (line.rate).toFixed(2) }}</h4>
          <h4>{{ (line.qte * line.rate).toFixed(2) }}</h4>
        </div>

        <div class="invoice-line">
          <h4>subtotal</h4>
          <h4>{{ (project.invoiceId.subTotal).toFixed(2) }}</h4>
        </div>

        <div class="empty-line"/>

        <div v-for='(tx, index) in project.invoiceId.taxes' class="invoice-line" :key='index'>
          <h4>{{ tx.name }} - {{ tx.rate }}%</h4>
          <h4>{{ (tx.amount).toFixed(2) }}</h4>
        </div>

        <div class="empty-line"/>

        <div class="invoice-line">
          <h4>total</h4>
          <h4>{{ project.invoiceId.total }}</h4>
        </div>


      </div>

      <div class="note-cont">
        <h4>Note</h4>
        <p>
          {{ 'asdfsdfasfdasdfsadfsadfsdf' }}
        </p>
      </div>

      <div class="hori-cont-list">
        <h5>TPS 737074872 RT0001 / </h5>
        <h5> TVQ 1226895015 TQ0001</h5>
      </div>

      <button v-if='project.invoiceId.paymentStatus==="pending"' class='btn-payment' @click='payInvoice'>Régler ma facture</button>

    </div>
  </div>
</template>

<script>

let stripe = window.Stripe("pk_test_x3HlU0PmeHtDDzft73RmOi9X");

if(process.env.NODE_ENV==='production'){
  stripe = window.Stripe("pk_live_W4ZuQLXj0Cd2rs1hoxDYgayV");
}

import { makeInvoicePayment } from '@/apiCall';


import * as dayjs from 'dayjs';

export default {
  name: 'InvoiceViewer',
  props: {
    project: Object,
    setAlert: Function
  },
  methods: {
    parseDate(date){
      return dayjs(date).format('LL')
    },
    async payInvoice(){
      try {

        let { status, CHECKOUT_SESSION_ID} = await makeInvoicePayment(this.project.invoiceId._id)

        if(status==='checkoutRedirect'){
          stripe.redirectToCheckout({
              sessionId: CHECKOUT_SESSION_ID
            });
        }

        this.setAlert({
          type: 'info',
          data: {
            status: 'success',
            message: 'Payment successfull!'
          }
        })

        this.$emit('closeViewer')

      } catch (e) {
        this.setAlert({
          type: 'info',
          data: {
            status: 'error',
            message: 'Opération de paiement non disponible, veuiller communiquer avec nous au 418-802-4227.'
          }
        })
        console.log(e)
      }
    }
  }
}
</script>
